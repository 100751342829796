import { Calendar, TrendUp01 } from '@untitled-ui/icons-react';
import moment from 'moment';
import type { GetStaticProps } from 'next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useEffect, useState } from 'react';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

import { EmptyAvatar } from '@/assets';
import type {
  Apartment,
  Booking,
  Deposit,
  Reservation,
} from '@/backend/directusSchema';
import CustomImage from '@/components/common/CustomImage';
import EmptyData from '@/components/common/EmptyData';
import { IconLabel } from '@/components/common/IconLabel';
import SkeletonText from '@/components/common/SkeletonText';
import ApartmentApi from '@/services/apartmentApi';
import BookingApi from '@/services/bookingApi';
import DepositApi from '@/services/depositApi';
import ReservationApi from '@/services/reservationApi';
import useUserService from '@/services/userService';
import AuthProtected from '@/template/AuthProtected';
import LandingPageTemplate from '@/template/LandingPage';

export const getStaticProps = (async (context) => {
  return {
    props: {
      ...(await serverSideTranslations(context.locale || 'vi', ['common'])),
    },
    revalidate: 60,
  };
}) satisfies GetStaticProps<{}>;

const mockData = [{}, {}, {}, {}, {}, {}];

const UserCard = () => {
  const { currentUser } = useUserService((state) => ({
    currentUser: state.currentUser,
  }));
  const sessionLoading = currentUser == null;

  return (
    <div className="flex flex-row lg:flex-col">
      <div className="flex flex-row items-center gap-4 lg:flex-col lg:gap-6 lg:rounded-xl lg:border lg:p-5">
        <CustomImage
          src={
            currentUser?.avatar != null
              ? `${currentUser.avatar}?width=320`
              : undefined
          }
          alt={'UserAvatar'}
          sizes="100"
          className={`size-12 rounded-full border lg:size-52 lg:rounded-lg xl:size-80 ${
            sessionLoading ? 'animate-skeleton bg-secondary-100' : ''
          }`}
          fallback={EmptyAvatar}
        />
        <div>
          <div className="flex w-max flex-col gap-1 self-start">
            <SkeletonText
              className="font-medium text-typo-300 sm:text-lg"
              loading={sessionLoading}
            >
              {!sessionLoading ? currentUser.name : ''}
            </SkeletonText>
            {/* <SkeletonText
              className="text-sm text-typo-100 sm:text-base"
              loading={sessionLoading}
              placeholder="Sales Manager"
            >
              Sales Manager
            </SkeletonText> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const DepositOverview = ({
  totalApartmentDeposits,
}: {
  totalApartmentDeposits?: number;
}) => {
  return (
    <div className="flex w-full gap-6 rounded-xl border p-6 md:flex-col">
      <IconLabel
        icon={<TrendUp01 className="text-success-600" />}
        className="border-success-50 bg-success-100"
      />
      <div className="flex flex-col gap-2">
        <span className="text-sm font-medium text-typo-100">
          Tổng số căn hộ đã thực hiện cọc
        </span>
        <SkeletonText
          className="w-max text-3xl font-semibold text-typo-300 sm:text-4xl"
          loading={totalApartmentDeposits == null}
          placeholder="00"
        >
          {totalApartmentDeposits}
        </SkeletonText>
      </div>
    </div>
  );
};

const ReservationOverview = ({
  totalApartmentReservations,
}: {
  totalApartmentReservations?: number;
}) => {
  return (
    <div className="flex w-full gap-6 rounded-xl border p-5 md:flex-col">
      <IconLabel
        icon={<TrendUp01 className="text-success-600" />}
        className="border-success-50 bg-success-100"
      />
      <div className="flex flex-col gap-2">
        <span className="text-sm font-medium text-typo-100">
          Số căn đang được giữ chỗ
        </span>
        <SkeletonText
          className="w-max text-3xl font-semibold text-typo-300 sm:text-4xl"
          loading={totalApartmentReservations == null}
          placeholder="00"
        >
          {totalApartmentReservations}
        </SkeletonText>
      </div>
    </div>
  );
};

const BookingCard = ({ booking }: { booking: Booking }) => {
  const apartment: Apartment = booking?.apartment_id as Apartment;
  return (
    <div className="flex flex-col gap-5 rounded-xl border border-gray-200 px-4 pb-3 pt-5 shadow-sm transition delay-150 duration-200 ease-in-out hover:scale-[102%] hover:cursor-pointer md:mx-0">
      <div className="flex flex-row items-center gap-3">
        <IconLabel
          icon={<Calendar className="text-success-600" />}
          className="border-success-50 bg-success-100"
        />
        <div className="flex flex-col justify-center gap-1">
          <SkeletonText
            className="font-semibold text-typo-300"
            loading={booking?.id == null}
            placeholder="Booking căn hộ LK-01"
          >
            Booking căn hộ {apartment?.code}
          </SkeletonText>
          <SkeletonText
            className="text-sm text-typo-100"
            loading={booking?.id == null}
            placeholder="09:05 - 10:05 09/05/2024"
          >
            {moment(booking.start_time).format('HH:mm')} -{' '}
            {moment(booking.expired_time).format('HH:mm DD/MM/YYYY')}
          </SkeletonText>
        </div>
      </div>
      <div className="flex items-end justify-end text-sm font-semibold text-primary-700">
        <SkeletonText loading={booking?.id == null} placeholder="Xem chi tiết">
          <Link href={`/user/bookings/${booking.id}`} shallow>
            Xem chi tiết
          </Link>
        </SkeletonText>
      </div>
    </div>
  );
};

const DepositCard = ({ deposit }: { deposit: Deposit }) => {
  const apartment: Apartment = deposit?.apartment_id as Apartment;
  return (
    <div className="flex flex-col gap-5 rounded-xl border border-gray-200 px-4 pb-3 pt-5 shadow-sm transition delay-150 duration-200 ease-in-out hover:scale-[102%] hover:cursor-pointer md:mx-0">
      <div className="flex flex-row items-center gap-3">
        <IconLabel
          icon={<Calendar className="text-success-600" />}
          className="border-success-50 bg-success-100"
        />
        <div className="flex flex-col justify-center gap-1">
          <SkeletonText
            className="font-semibold text-typo-300"
            loading={deposit?.id == null}
            placeholder="Cọc căn hộ LK-01"
          >
            Cọc căn hộ {apartment?.code}
          </SkeletonText>
          <SkeletonText
            className="text-sm text-typo-100"
            loading={deposit?.id == null}
            placeholder="Mã phiếu cọc: DP-01"
          >
            Mã phiếu cọc: DP-{deposit?.id}
          </SkeletonText>
          <SkeletonText
            className="text-sm text-typo-100"
            loading={deposit?.id == null}
            placeholder="10:05 09/05/2024"
          >
            {moment(deposit?.created_at).format('HH:mm DD/MM/YYYY')}
          </SkeletonText>
        </div>
      </div>
      <div className="flex items-end justify-end text-sm font-semibold text-primary-700">
        <SkeletonText loading={deposit?.id == null} placeholder="Xem chi tiết">
          <Link href={`/user/deposits/${deposit?.id}`} shallow>
            Xem chi tiết
          </Link>
        </SkeletonText>
      </div>
    </div>
  );
};

const ReservationCard = ({ reservation }: { reservation: Reservation }) => {
  const apartment: Apartment = reservation?.apartment_id as Apartment;
  return (
    <div className="flex flex-col gap-5 rounded-xl border border-gray-200 px-4 pb-3 pt-5 shadow-sm transition delay-150 duration-200 ease-in-out hover:scale-[102%] hover:cursor-pointer md:mx-0">
      <div className="flex flex-row items-center gap-3">
        <IconLabel
          icon={<Calendar className="text-success-600" />}
          className="border-success-50 bg-success-100"
        />
        <div className="flex flex-col justify-center gap-1">
          <SkeletonText
            className="font-semibold text-typo-300"
            loading={reservation?.id == null}
            placeholder="Giữ chỗ căn hộ LK-01"
          >
            Giữ chỗ căn hộ {apartment?.code}
          </SkeletonText>
          <SkeletonText
            className="text-sm text-typo-100"
            loading={reservation?.id == null}
            placeholder="Mã phiếu giữ chỗ: RV-01"
          >
            Mã phiếu giữ chỗ: RV-{reservation?.id}
          </SkeletonText>
          <SkeletonText
            className="text-sm text-typo-100"
            loading={reservation?.id == null}
            placeholder="10:05 09/05/2024"
          >
            {moment(reservation?.created_at).format('HH:mm DD/MM/YYYY')}
          </SkeletonText>
        </div>
      </div>
      <div className="flex items-end justify-end text-sm font-semibold text-primary-700">
        <SkeletonText
          loading={reservation?.id == null}
          placeholder="Xem chi tiết"
        >
          <Link href={`/user/reservations/${reservation?.id}`} shallow>
            Xem chi tiết
          </Link>
        </SkeletonText>
      </div>
    </div>
  );
};

const BookingList = ({ bookings }: { bookings?: Booking[] }) => {
  return (
    <div className=" grid gap-6 md:grid-cols-2 xl:grid-cols-3">
      {(bookings ?? (mockData as Booking[])).map((item, i) => (
        <BookingCard key={i} booking={item} />
      ))}
      {bookings != null && bookings.length === 0 && (
        <EmptyData
          className="col-span-full flex items-center justify-center py-10"
          title=""
          subtitle="Bạn chưa có dữ liệu Booking xem nhà nào"
        />
      )}
    </div>
  );
};

const DepositList = ({ deposits }: { deposits?: Deposit[] }) => {
  return (
    <div className=" grid gap-6 md:grid-cols-2 xl:grid-cols-3">
      {(deposits ?? (mockData as Deposit[])).map((item, i) => (
        <DepositCard key={i} deposit={item} />
      ))}
      {deposits != null && deposits.length === 0 && (
        <EmptyData
          className="col-span-full flex items-center justify-center py-10"
          title=""
          subtitle="Bạn chưa có dữ liệu đặt cọc nào"
        />
      )}
    </div>
  );
};

const ReservationList = ({
  reservations,
}: {
  reservations?: Reservation[];
}) => {
  return (
    <div className=" grid gap-6 md:grid-cols-2 xl:grid-cols-3">
      {(reservations ?? (mockData as Reservation[])).map((item, i) => (
        <ReservationCard key={i} reservation={item} />
      ))}
      {reservations != null && reservations.length === 0 && (
        <EmptyData
          className="col-span-full flex items-center justify-center py-10"
          title=""
          subtitle="Bạn chưa có dữ liệu giữ chỗ nào"
        />
      )}
    </div>
  );
};

const LandingPage = () => {
  const { t } = useTranslation('common');
  const { locale } = useRouter();
  const session = useSessionContext();
  const sessionLoading = session.loading || !session.doesSessionExist;
  const [bookings, setBookings] = useState<Booking[]>();
  const [reservations, setReservations] = useState<Reservation[]>();
  const [deposits, setDeposits] = useState<Deposit[]>();
  const [statistic, setStatistic] = useState<any>();

  useEffect(() => {
    if (!session.loading && session.doesSessionExist) {
      if (deposits == null) {
        DepositApi.filterDeposits({
          apartmentCode: undefined,
          page: 0,
          size: 6,
        }).then((res) => !res.status && setDeposits(res));
      }

      if (reservations == null) {
        ReservationApi.filterReservations({
          apartmentCode: undefined,
          page: 0,
          size: 6,
        }).then((res) => !res.status && setReservations(res));
      }

      if (deposits == null) {
        BookingApi.filterBookings({
          apartmentCode: undefined,
          page: 0,
          size: 6,
        }).then((res) => !res.status && setBookings(res));
      }

      if (statistic == null) {
        ApartmentApi.getStatistic().then(
          (res) => !res.status && setStatistic(res)
        );
      }
    }
  }, [session]);

  return (
    <LandingPageTemplate
      seo={{
        title: t('seo.home.title').toString(),
        description: t('seo.home.description').toString(),
        canonical: process.env.NEXT_PUBLIC_BASE_URL,
        openGraph: {
          type: 'website',
          url: process.env.NEXT_PUBLIC_BASE_URL,
          title: t('seo.home.title').toString(),
          description: t('seo.home.description').toString(),
          locale,
          images: [
            {
              url: `${process.env.NEXT_PUBLIC_BASE_URL}/assets/images/meta-image.jpg`,
              alt: 'WinLand meta image alt',
            },
          ],
        },
      }}
    >
      <div className="mx-auto mt-5 min-h-screen w-full max-w-[1440px] flex-col px-5 pb-20 pt-4">
        <AuthProtected>
          <>
            <div className="mb-5 hidden lg:block">
              <SkeletonText
                className="text-3xl font-bold text-typo-300"
                loading={sessionLoading}
              >
                {`Xin chào, ${
                  !sessionLoading ? session?.accessTokenPayload?.fullname : ''
                }`}
              </SkeletonText>
            </div>
            <div className="flex w-full flex-col items-start gap-6 lg:flex-row">
              <UserCard />
              <div className="flex w-full flex-col gap-6">
                <div className="flex w-full flex-col gap-6 md:flex-row">
                  <DepositOverview
                    totalApartmentDeposits={statistic?.deposit}
                  />
                  <ReservationOverview
                    totalApartmentReservations={statistic?.reservation}
                  />
                </div>
                <div className="flex w-full flex-col">
                  <div className="mb-6 flex w-full flex-row justify-between border-b pb-4 ">
                    <span className="font-semibold md:text-lg">
                      Danh sách đặt lịch xem nhà
                    </span>
                    <Link
                      href={'/user/bookings'}
                      className="text-sm font-semibold text-primary-700 hover:text-primary-700"
                      shallow
                    >
                      <span>Xem tất cả</span>
                    </Link>
                  </div>
                  <BookingList bookings={bookings} />
                </div>
                <div className="flex w-full flex-col">
                  <div className="mb-6 flex w-full flex-row justify-between border-b pb-4 ">
                    <span className="font-semibold md:text-lg">
                      Danh sách đặt cọc
                    </span>
                    <Link
                      href={'/user/deposits'}
                      className="text-sm font-semibold text-primary-700 hover:text-primary-700"
                      shallow
                    >
                      <span>Xem tất cả</span>
                    </Link>
                  </div>
                  <DepositList deposits={deposits} />
                </div>
                <div className="flex w-full flex-col">
                  <div className="mb-6 flex w-full flex-row justify-between border-b pb-4 ">
                    <span className="font-semibold md:text-lg">
                      Danh sách giữ chỗ
                    </span>
                    <Link
                      href={'/user/reservations'}
                      className="text-sm font-semibold text-primary-700 hover:text-primary-700"
                      shallow
                    >
                      <span>Xem tất cả</span>
                    </Link>
                  </div>
                  <ReservationList reservations={reservations} />
                </div>
              </div>
            </div>
          </>
        </AuthProtected>
      </div>
    </LandingPageTemplate>
  );
};

export default LandingPage;
