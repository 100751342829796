import type { HTMLProps } from 'react';

export interface IconLabelProps extends HTMLProps<HTMLDivElement> {
  icon: JSX.Element;
}

export const IconLabel = ({ icon, className = '' }: IconLabelProps) => {
  return (
    <div className={`${className} size-fit rounded-full border-8 p-2 text-xl`}>
      {icon}
    </div>
  );
};
