import axios from 'axios';

const getDetailById = async (id: string) => {
  return axios.get(`/api/apartments/${id}`).then((res) => res.data);
};

const getAllApartments = async () => {
  return axios.get(`/api/apartments`).then((res) => res.data);
};

const getStatistic = async () => {
  return axios.get(`/api/apartments/statistic`).then((res) => res.data);
};

const getCurrentBookingTime = async (id: string) => {
  return axios
    .get(`/api/apartments/${id}/current-booking`)
    .then((res) => res.data);
};

const ApartmentApi = {
  getDetailById,
  getAllApartments,
  getStatistic,
  getCurrentBookingTime,
};

export default ApartmentApi;
