import { useRouter } from 'next/router';
import { type HTMLProps, useEffect } from 'react';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

import useUserService from '@/services/userService';

export interface AuthProtectedProps extends HTMLProps<HTMLDivElement> {
  forceRedirect?: string;
}

const AuthProtected = ({ forceRedirect, children }: AuthProtectedProps) => {
  const router = useRouter();
  const session = useSessionContext();
  const { currentUser, fetchUser, clearCurrentUser } = useUserService(
    (state) => ({
      currentUser: state.currentUser,
      fetchUser: state.fetchUser,
      clearCurrentUser: state.clearCurrentUser,
    })
  );

  useEffect(() => {
    if (
      !session.loading &&
      (session.accessTokenPayload == null ||
        !session.accessTokenPayload.verified)
    ) {
      const url = {
        pathname: '/login',
        query: {
          redirect: currentUser != null ? '/' : forceRedirect ?? router.asPath,
        },
      };
      router
        .replace(url, undefined, {
          shallow: true,
        })
        .then(() => clearCurrentUser());
    } else if (
      !session.loading &&
      session.doesSessionExist &&
      currentUser == null
    ) {
      fetchUser();
    }
  }, [session, currentUser]);

  return <>{children}</>;
};

export default AuthProtected;
